import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getDefaultMethod = () => axios.get(`${getAccountId()}/billing/methods/default`)
const getBillingMethods = () => axios.get(`${getAccountId()}/billing/methods`)
const getBillingPrepare = () => axios.get(`${getAccountId()}/billing/prepare`)

const savePaymentMethod = formData => axios.post(`${getAccountId()}/billing/methods`, formData)
const billingProcess = formData => axios.post(`${getAccountId()}/billing/process`, formData)

const uploadVerificationFiles = ({ method, formData, config }) => axios.post(`${getAccountId()}/billing/${method}/documents`, formData, config)

const setDefaultMethod = formData => axios.patch(`${getAccountId()}/billing/methods/default`, formData)
const autoRecharge = formData => axios.patch(`${getAccountId()}/settings/auto-recharge`, formData)

const deletePaymentMethod = formData => axios.delete(`${getAccountId()}/billing/methods`, { params: formData})

export {
  getDefaultMethod, getBillingMethods, getBillingPrepare, savePaymentMethod, billingProcess, uploadVerificationFiles, setDefaultMethod, autoRecharge, deletePaymentMethod
}